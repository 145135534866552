<template>
    <form method="POST" :action="route('therapy.store', consultation)" enctype="multipart/form-data" autocomplete="on">
        <input type="hidden" name="_token" :value="csrf" />
        <!-- @csrf -->
        <!-- Bolesti opcije -->
        <div class="col mb-3">
            <div class="card shadow-sm">
                <h5 class="card-header bg-disease text-white">
                    <ul class="list-inline m-0">
                        <li class="list-inline-item">
                            Bolesti
                        </li>
                        <li class="list-inline-item">
                            <a @click="addDisease" id="add-disease" data-toggle="tooltip" title="Dodaj novu bolest"><i class="bi bi-plus-circle"></i></a>
                        </li>
                    </ul>
                </h5>
                <div class="card-body text-muted">
                    <div
                        class="diseases"
                        v-if="form.diseases.length > 0"
                        v-for="(disease, index) in form.diseases"
                        :key="`diseases-${index}`"
                        :id="`diseases[${index}]`"
                    >
                        <div class="row g-3 mb-2">
                            <div class="col">
                                <div class="row g-2">
                                    <div class="col-12">
                                        <div class="form-floating">
                                            <input
                                                :id="`diseases[${index}][disease]`"
                                                :name="`diseases[${index}][disease]`"
                                                v-model="disease.disease"
                                                type="text"
                                                class="form-control text-disease"
                                                placeholder="Bolest"
                                                @input="debouncedFindDisease(index)"
                                                required
                                            />
                                            <label>Bolest</label>
                                        </div>
                                    </div>
                                    <div 
                                        class="col-12"
                                        v-if="
                                            (filteredDiseases[index] && filteredDiseases[index].length) &&
                                            (form.diseases[index].disease != '' || form.diseases[index].disease != null)
                                        "
                                    >
                                        <ul class="list-group">
                                            <li 
                                                v-for="suggestedDisease in filteredDiseases[index]"
                                                :key="`diseases[${index}][${suggestedDisease}]`"
                                                @click="selectDisease(index, suggestedDisease)"
                                                class="list-group-item list-group-item-action list-group-item-light d-flex justify-content-between align-items-start"
                                                style="cursor: pointer;"
                                            >
                                                {{ suggestedDisease.name }}
                                                <span class="badge bg-secondary text-disease rounded-pill">{{ suggestedDisease.MKB10 }}</span>
                                            </li>
                                            <li 
                                                class="list-group-item list-group-item-action list-group-item-light text-danger"
                                                @click="clearDisease(index)"
                                                style="cursor: pointer;"
                                            >
                                                <i class="bi bi-arrow-up-circle"></i> Zatvori
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-floating">
                                    <input
                                        :id="`diseases[${index}][MKB10]`"
                                        :name="`diseases[${index}][MKB10]`"
                                        v-model="disease.MKB10"
                                        type="text"
                                        placeholder="MKB-10"
                                        class="form-control"
                                    />
                                    <label>MKB-10</label>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-floating">
                                    <input
                                        :id="`diseases[${index}][MKB11]`"
                                        :name="`diseases[${index}][MKB11]`"
                                        v-model="disease.MKB11"
                                        type="text"
                                        placeholder="MKB-11"
                                        class="form-control"
                                    />
                                    <label>MKB-11</label>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-floating">
                                    <select
                                        :id="`diseases[${index}][therapy_goal_achieved]`"
                                        :name="`diseases[${index}][therapy_goal_achieved]`"
                                        v-model="disease.therapy_goal_achieved"
                                        class="form-select w-100"
                                    >
                                    <option value=""></option>
                                    <option value="Ne">Ne</option>
                                    <option value="Da">Da</option>
                                    <option value="Nije primjenjivo">Nije primjenjivo</option>
                                    </select>
                                    <label data-toggle="tooltip" title="Terapijski cilj postignut">Terapijski cilj postignut</label>
                                </div>
                                
                            </div>
                            <div class="col-md-auto align-self-center text-center">
                                <a @click="removeDisease(index)" id="remove-disease" class="text-danger" data-toggle="tooltip" title="Ukloni bolest"><i class="bi bi-dash-circle"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col ">
                            <div class="form-floating">
                                <textarea id="history" type="text" style="height: 100px" class="form-control" name="history" v-model="form.history"></textarea>
                                <label>Povijest bolesti</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col mb-3">
            <div class="card shadow-sm">
                <h5 class="card-header bg-medicine text-white">
                    <ul class="list-inline m-0">
                        <li class="list-inline-item">
                            Lijekovi
                        </li>
                        <li class="list-inline-item">
                            <a @click="addMedicine" id="add-medicine" data-toggle="tooltip" title="Dodaj novi lijek"><i class="bi bi-plus-circle"></i></a>
                        </li>
                    </ul>
                </h5>
                <div class="card-body text-muted bg-white">
                    <div
                        class="medicine"
                        v-if="form.medicines.length > 0"
                        v-for="(medicine, index) in form.medicines"
                        :key="`medicines-${index}`"
                        :id="`medicines-[${index}]`"
                    >
                        <div class="row g-2 mb-2">
                            <div class="col-md">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-floating">
                                            <textarea
                                                class="form-control text-medicine"
                                                type="text"
                                                :name="`medicines[${index}][drug]`"
                                                v-model="medicine.drug"
                                                data-toggle="tooltip"
                                                title="Lijek koji se propisuje"
                                                placeholder="Lijek"
                                                @input="debouncedFindDrug(index)"
                                                required
                                            ></textarea>
                                            <label>Lijek</label>
                                        </div>
                                    </div>
                                    <div 
                                        class="col-12"
                                        v-if="
                                            (filteredDrugs[index] && filteredDrugs[index].length) &&
                                            (form.medicines[index].drug != '' || form.medicines[index].drug != null)
                                        "
                                    >
                                        <ul class="list-group">
                                            <li 
                                                v-for="suggestedDrug in filteredDrugs[index]"
                                                :key="`medicines[${index}][${suggestedDrug}]`"
                                                @click="selectDrug(index, suggestedDrug)"
                                                class="list-group-item list-group-item-action list-group-item-light d-flex justify-content-between align-items-start"
                                                style="cursor: pointer;"
                                            >
                                                {{ suggestedDrug.name }}
                                                <span class="badge rounded-pill bg-secondary text-medicine">{{ suggestedDrug.ATK }}</span>
                                            </li>
                                            <li 
                                                class="list-group-item list-group-item-action list-group-item-light text-danger"
                                                @click="clearDrug(index)"
                                                style="cursor: pointer;"
                                            >
                                                <i class="bi bi-arrow-up-circle"></i> Zatvori
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-floating">
                                    <textarea
                                        type="text"
                                        :name="`medicines[${index}][ATK]`"
                                        v-model="medicine.ATK"
                                        class="form-control"
                                        data-toggle="tooltip"
                                        title="ATK klasifikacija"
                                        placeholder="ATK"
                                    ></textarea>
                                    <label>ATK</label>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-floating">
                                    <textarea
                                        type="text"
                                        class="form-control"
                                        :name="`medicines[${index}][scheme]`"
                                        v-model="medicine.scheme"
                                        data-toggle="tooltip"
                                        title="Doza / Vrijeme uzimanja / S hranom ili natašte / Način uzimanja)"
                                        placeholder="Shema doziranja"
                                    ></textarea>
                                    <label>Shema doziranja</label>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-floating">
                                    <textarea
                                        type="text"
                                        class="form-control"
                                        :name="`medicines[${index}][length]`"
                                        v-model="medicine.length"
                                        placeholder="Duljina trajanja terapije"
                                        data-toggle="tooltip"
                                        title="Početak uzimanja terapije / Izmjene / Promjene terapije"
                                    ></textarea>
                                    <label>Duljina trajanja terapije</label>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-floating">
                                    <textarea
                                        type="text"
                                        class="form-control"
                                        :name="`medicines[${index}][effectiveness]`"
                                        v-model="medicine.effectiveness"
                                        placeholder="Učinkovitost"
                                        data-toggle="tooltip"
                                        title="Objetkivni / Subjektivni pokazatelji"
                                    ></textarea>
                                    <label>Učinkovitost</label>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-floating">
                                    <textarea
                                        type="text"
                                        class="form-control"
                                        :name="`medicines[${index}][security]`"
                                        v-model="medicine.security"
                                        placeholder="Sigurnost"
                                        data-toggle="tooltip"
                                        title="Objetkivni / Subjektivni pokazatelji"
                                    ></textarea>
                                    <label>Sigurnost</label>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-floating">                   
                                    <textarea
                                        type="text"
                                        class="form-control"
                                        :name="`medicines[${index}][adherence]`"
                                        v-model="medicine.adherence"
                                        data-toggle="tooltip"
                                        title="Zaboravljivost, poteškoće u prepoznavanju lijeka, poteškoće pri primjeni lijeka, problem u kupnji/ dostupnosti lijeka, nerazumijevanje uputa za primjenu lijeka, svjesno neuzimanje lijeka"
                                        placeholder="Adherencija"
                                    ></textarea>
                                    <label>Adherencija</label>
                                </div>
                            </div>
                            <div class="col-md-auto align-self-center text-center">
                                <a @click="removeMedicine(index)" id="remove-medicine" class="text-danger" data-toggle="tooltip" title="Ukloni lijek"><i class="bi bi-dash-circle"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col mb-3">
            <div class="card shadow-sm">
                <h5 class="card-header bg-problem text-white">
                    <ul class="list-inline m-0">
                        <li class="list-inline-item">
                            Terapijski problemi
                        </li>
                        <li class="list-inline-item">
                            <a @click="addProblem" id="add-problem" data-toggle="tooltip" title="Dodaj terapijski problem">
                                <i class="bi bi-plus-circle"></i>
                            </a>
                        </li>
                    </ul>
                </h5>
                <div class="card-body text-muted bg-white">
                    <div
                        class="problems"
                        v-if="form.problems.length > 0"
                        v-for="(problem, index) in form.problems"
                        :key="`problems-${index}`"
                        :id="`problems[${index}]`"
                    >
                        <div class="row g-2 mb-4">   
                            <div class="col-11 text-muted">
                                <div class="form-floating">
                                    <select
                                        class="form-select text-problem w-100 bg-primary-subtle"
                                        :id="`problems[${index}][problem_id]`"
                                        :name="`problems[${index}][problem_id]`"
                                        v-model="problem.problem_id"
                                        required
                                    >
                                        <option value="" selected>-- Odaberi --</option>
                                        <optgroup
                                            v-for="problemGroup in problemGroups"
                                            :key="`pg-${problemGroup.id}`"
                                            :label="`${problemGroup.number}. ${problemGroup.description}`"
                                        >
                                            <option
                                                v-for="problemItem in problemGroup.problem"
                                                :key="`problem-item-${problemItem.id}`"
                                                :value="problemItem.id"
                                                v-text="`${problemItem.number} ${problemItem.description}`"
                                            ></option>
                                        </optgroup>
                                    </select>
                                    <label>Problem</label>
                                </div>
                            </div>
                            <div class="col-auto align-self-center text-center mb-2">
                                <a @click="removeProblem(index)" id="remove-problem" class="text-danger" data-toggle="tooltip" title="Ukloni terapijski problem"><i class="bi bi-dash-circle"></i></a>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating">
                                    <input
                                        :id="`problems[${index}][drug]`"
                                        :name="`problems[${index}][drug]`"
                                        v-model="problem.drug"
                                        type="text"
                                        class="form-control"
                                    >
                                    <label>Lijek</label>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="form-floating">
                                    <input
                                        :id="`problems[${index}][target]`"
                                        :name="`problems[${index}][target]`"
                                        v-model="problem.target"
                                        type="text"
                                        class="form-control"
                                    >
                                    <label>Terapijski cilj</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating">
                                    <select
                                        class="form-select w-100"
                                        :id="`problems[${index}][intervention_type_id]`"
                                        :name="`problems[${index}][intervention_type_id]`"
                                        v-model="problem.intervention_type_id"
                                        required
                                    >
                                        <option value="" selected disabled>-- Odaberi --</option>
                                            <option
                                                v-for="interventionType in interventionTypes"
                                                :key="`intervention-type-${interventionType.id}`"
                                                :value="interventionType.id"
                                                v-text="interventionType.description"
                                            ></option>
                                    </select>
                                    <label>Vrsta intervencije</label>
                                </div>
                            </div>                  
                            <div class="col-md-2">
                                <div class="form-floating">
                                    <select
                                        :id="`problems[${index}][acceptedby]`"
                                        :name="`problems[${index}][acceptedby]`"
                                        v-model="problem.acceptedby"
                                        class="form-select w-100"
                                        required
                                    >
                                        <option value="" selected disabled>-- Odaberi --</option>
                                        <option value="Čekanje povratne informacije - nedefinirano">Čekanje povratne informacije - nedefinirano</option>
                                        <option value="Pacijent: Ne">Pacijent: Ne</option>
                                        <option value="Pacijent: Da">Pacijent: Da</option>
                                        <option value="Čekanje povratne informacije - pacijent">Čekanje povratne informacije - pacijent</option>
                                        <option value="Neuspješan kontakt s pacijentom">Neuspješan kontakt s pacijentom</option>
                                        <option value="LOM: Ne">LOM: Ne</option>
                                        <option value="LOM: Da">LOM: Da</option>
                                        <option value="Liječnik nedefinirani: Da">Liječnik nedefinirani: Da</option>
                                        <option value="Čekanje povratne informacije - liječnik">Čekanje povratne informacije - liječnik</option>
                                        <option value="Neuspješan kontakt s liječnikom">Neuspješan kontakt s liječnikom</option>
                                        <option value="Specijalist: Ne">Specijalist: Ne</option>
                                        <option value="Specijalist: Da">Specijalist: Da</option>
                                        <option value="Čekanje povratne informacije - specijalist">Čekanje povratne informacije - specijalist</option>
                                        <option value="Neuspješan kontakt sa specijalistom">Neuspješan kontakt sa specijalistom</option>
                                </select>
                                <label>Prihvaćeno od</label>
                                </div>    
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <textarea
                                        :id="`problems[${index}][intervention]`"
                                        :name="`problems[${index}][intervention]`"
                                        v-model="problem.intervention"
                                        type="text"
                                        rows="2"
                                        class="form-control"
                                    ></textarea>
                                    <label>Intervencija:</label>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-floating">
                                    <select
                                        :id="`problems[${index}][status]`"
                                        :name="`problems[${index}][status]`"
                                        v-model="problem.status"
                                        class="form-select w-100"
                                    >
                                        <option value="" selected disabled>-- Odaberi --</option>
                                        <option value="0">Ne</option>
                                        <option value="1">Da</option>
                                    </select>
                                    <label>Riješeno</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col mb-3">
            <div class="card shadow-sm">
                <h5 class="card-header bg-cold text-white">
                    Dodatne informacije
                </h5>
                <div class="card-body text-muted">
                    <div class="form-floating">
                        <textarea
                            id="info"
                            name="info"
                            v-model="form.info"
                            type="text"
                            rows="2"
                            class="form-control mb-2"
                            placeholder="Dodatne informacije"
                        ></textarea>
                        <label>Dodatne informacije</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-auto text-end">
            <button type="submit" class="btn btn-primary">
                Spremi
            </button>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        consultation: Object,
        old: {
            type: Object,
            default: {},
            required: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        problemGroups: {
            type: Array,
            required: true,
        },
        interventionTypes: {
            type: Array,
            required: true,
        }
    },

    data() {
        return {
            csrf: null,
            filteredDiseases: [],
            filteredDrugs: [],
            debouncedDiseaseQuery: _.debounce(this.findDisease, 1000),
            debouncedDrugQuery: _.debounce(this.findDrug, 1000),
            form: {
                diseases: [
                    {
                        disease: null,
                        MKB10: null,
                        MKB11: null,
                        therapy_goal_achieved: null,
                    }
                ],
                history: null,
                medicines: [
                    {
                        drug: null,
                        ATK: null,
                        scheme: null,
                        length: null,
                        effectiveness: null,
                        security: null,
                        adherence: null,
                    }
                ],
                problems: [
                    {
                        problem_id: null,
                        drug: null,
                        target: null,
                        intervention_type_id: null,
                        acceptedby: null,
                        intervention: null,
                        status: null,
                    }
                ],
                info: null,
            },
        }
    },

    created() {
        this.csrf = document.querySelector('meta[name="csrf-token"]').content;

        if (!_.isEmpty(this.old)) {
            this.form = {
                ...this.form,
                ...this.old,
            };
        }
        window.addEventListener('beforeunload', this.handler)
    },

    methods: {
        findDisease(index) {
            if(this.form.diseases[index].disease != '' && this.form.diseases[index].disease != null) {
                axios.get(route('disease.find', this.form.diseases[index].disease))
                .then(response => {
                    this.filteredDiseases[index] = response.data;
                })
                .catch(error => console.error('Error:', error));
            }
            
        },
        debouncedFindDisease(index) {
            this.debouncedDiseaseQuery(index);
        },
        selectDisease(index, suggestion) {
            const selectedDisease = this.form.diseases[index];
            selectedDisease.disease = suggestion.name;
            //MKB10
            if((selectedDisease.MKB10 == null || selectedDisease.MKB10 == '') && suggestion.MKB10 != null) {
                selectedDisease.MKB10 = suggestion.MKB10;
            }
            //MKB11
            if((selectedDisease.MKB11 == null || selectedDisease.MKB11 == '') && suggestion.MKB11 != null) {
                selectedDisease.MKB11 = suggestion.MKB11;
            }
            this.filteredDiseases[index] = [];
        },
        clearDisease(index) {
            this.filteredDiseases[index] = [];
        },
        addDisease() {
            this.form.diseases.push({
                disease: null,
                MKB10: null,
                MKB11: null,
                therapy_goal_achieved: null,
            });
        },
        removeDisease(index) {
            if(confirm("Jeste li sigurni da želite obrisati redak " + this.form.diseases[index].disease + "?")){
                this.form.diseases.splice(index, 1);
            }
        },
        findDrug(index) {
            if(this.form.medicines[index].drug != '' && this.form.medicines[index].drug != null) {
                axios.get(route('drug.find', this.form.medicines[index].drug))
                .then(response => {
                    this.filteredDrugs[index] = response.data;
                })
                .catch(error => console.error('Error:', error));
            }
            
        },
        debouncedFindDrug(index) {
            this.debouncedDrugQuery(index);
        },
        selectDrug(index, suggestion) {
            const selectedDrug = this.form.medicines[index];
            selectedDrug.drug = suggestion.name;
            //ATK
            if((selectedDrug.ATK == null || selectedDrug.ATK == '') && suggestion.ATK != null) {
                selectedDrug.ATK = suggestion.ATK;
            }
            this.filteredDrugs[index] = [];
        },
        clearDrug(index) {
            this.filteredDrugs[index] = [];
        },
        addMedicine() {
            this.form.medicines.push({
                drug: null,
                ATK: null,
                scheme: null,
                length: null,
                effectiveness: null,
                security: null,
                adherence: null,
            });
        },
        removeMedicine(index) {
            if(confirm("Jeste li sigurni da želite obrisati redak " + this.form.medicines[index].drug + "?")){
                this.form.medicines.splice(index, 1);
            }
        },
        addProblem() {
            this.form.problems.push({
                problem_id: null,
                drug: null,
                target: null,
                intervention_type_id: null,
                acceptedby: null,
                intervention: null,
                status: null,
            });
        },
        removeProblem(index) {
            if(confirm("Jeste li sigurni da želite obrisati terapijski problem?")){
                this.form.problems.splice(index, 1);
            }
        },
    },
}
</script>
